import { notificationShow } from 'utils/errors';
import {
  startLoading,
  stopLoading,
} from 'utils/common';
import {
  login,
  logout,
} from '../request/api';
import {
  STORAGE_TOKEN,
  STORAGE_ACCOUNT_ID,
} from './constants';

const cleanUp = () => {
  window.localStorage.removeItem(STORAGE_ACCOUNT_ID);
  window.localStorage.removeItem(STORAGE_TOKEN);
};

export default {
  cleanUp,
  isAuthenticated: () => !!window.localStorage.getItem(STORAGE_TOKEN),
  loginUser: async formData => {
    startLoading();
    try {
      const { data: { accessToken, metadata } } = await login(formData);

      window.localStorage.setItem(STORAGE_TOKEN, accessToken);
      window.localStorage.setItem(STORAGE_ACCOUNT_ID, metadata.accountId);
    } catch (e) {
      notificationShow(e, 'Login User');
    }
    stopLoading();
  },
  logoutUser: async () => {
    startLoading();
    try {
      await logout();

      cleanUp();
    } catch (e) {
      notificationShow(e, 'Logout User');
    }
    stopLoading();
  },
}