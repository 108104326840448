import React from 'react';
import { createUseStyles } from 'react-jss';
import ModalSensor from '../ModalSensor';
import styles from './styles';

const useStyles = createUseStyles(styles);

const ModalSensors = ({
  items,
  stateOn,
  stateOff,
  stateOffline,
  imageOn,
  imageOff,
  imageOffline,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      {items.map(i => (
        <ModalSensor
          key={i.id}
          stateOn={stateOn}
          stateOff={stateOff}
          stateOffline={stateOffline}
          imageOn={imageOn}
          imageOff={imageOff}
          imageOffline={imageOffline}
          {...i}
        />
      ))}
      <div className={classes.flexItem} />
      <div className={classes.flexItem} />
      <div className={classes.flexItem} />
    </div>
  )
};

export default ModalSensors;
