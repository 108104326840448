import React from 'react';
import { createUseStyles } from 'react-jss';
import Typography from '@material-ui/core/Typography';
import styles from './styles';

const useStyles = createUseStyles(styles);

const MobileMockUp = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Typography
        variant="h4"
      >
        Sorry!
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
      >
        We don't support mobile devices.
      </Typography>
    </div>
  )
};

export default MobileMockUp;
