import AWS from 'aws-sdk';
import AWSIoT from 'aws-iot-device-sdk';

export default {
  subscribe: (thingName, onStatus, onSateChange) => {
    AWS.config.region = window.AWS_REGION;
    AWS.config.accessKeyId = '';
    AWS.config.secretKey = '';
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: window.AWS_POOL_ID,
    });
    let shadowsRegistered = false;
    const cognitoIdentity = new AWS.CognitoIdentity();

    AWS.config.credentials.get(function(err, data) {
      if (!err) {
        var params = {
          IdentityId: AWS.config.credentials.identityId
        };
        cognitoIdentity.getCredentialsForIdentity(params, function(err, data) {
          if (!err) {
            window.shadows = AWSIoT.thingShadow({
              region: window.AWS_REGION,
              host: window.AWS_HOST,
              clientId: 'dn-dashboard-' + (Math.floor((Math.random() * 100000) + 1)),
              protocol: 'wss',
              maximumReconnectTimeMs: 8000,
              debug: true,
              accessKeyId: '',
              secretKey: '',
              sessionToken: ''
            });

            window.shadows.updateWebSocketCredentials(
              data.Credentials.AccessKeyId,
              data.Credentials.SecretKey,
              data.Credentials.SessionToken,
            );

            window.shadows.on('delta', function(name, stateObject) {
            });
            window.shadows.on('message', function(topic, message) {
            });
            window.shadows.on('foreignStateChange', function(name, operation, stateObject) {
              onSateChange(stateObject);
            });
            window.shadows.on('status', function(name, statusType, clientToken, stateObject) {
              onStatus(stateObject);
            });
            window.shadows.on('error', (error) => {
            });
            window.shadowConnectHandler = function() {
              if (!shadowsRegistered) {
                window.shadows.register(thingName, {
                  persistentSubscribe: true
                });

                shadowsRegistered = true;
              }

              setTimeout(function() {
                var opClientToken = window.shadows.get(thingName);
                if (opClientToken === null) {
                }
              }, 3000);
            };

            window.shadowReconnectHandler = function() {
            };

            window.shadows.on('connect', window.shadowConnectHandler);
            window.shadows.on('reconnect', window.shadowReconnectHandler);
          } else {
          }
        });
      } else {
      }
    });
  },
  unsubscribe: thingName => {
    window.shadows.unregister(thingName);
  },
};
