import imageOn from '../../../ModalSensorsFlood/images/on.svg';
import imageOff from '../../../ModalSensorsFlood/images/off.svg';
import imageOffline from '../../../ModalSensorsFlood/images/offline.svg';
import { parseCommon } from './sensors';

const parse = items => parseCommon({
  items,
  imageOn,
  imageOff,
  imageOffline,
});

export {
  parse,
};
