import React from 'react';
import { createUseStyles } from 'react-jss';
import SensorsCommon from './components/Sensor/SensorsCommon';
import SensorsSpecific from './components/Sensor/SensorsSpecific';
import Shadow from './components/Shadow';
import Store from './Store';
import AsyncServices from './utils/services/async';
import styles from './styles';

const useStyles = createUseStyles(styles);

const Dashboard = () => {
  const [ thing, setIotThing ] = React.useState(null);
  const classes = useStyles();
  
  React.useEffect(() => {
    AsyncServices
      .getIotThing()
      .then(thingName => {
        setIotThing(thingName);
      });
  }, []);

  return (
    <div className={classes.wrapper}>
      <div className={classes.bg} />
      <Store>
        {thing && <Shadow thing={thing} />}
        <SensorsCommon />
        <SensorsSpecific />
      </Store>
    </div>
  )
};

export default Dashboard;
