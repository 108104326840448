import { TEXT_CAPTION } from 'utils/constants/colors';

export default {
  image: {
    height: 140,
  },
  text: {
    color: TEXT_CAPTION,
    fontSize: 35,
    marginBottom: 20,
  },
};
