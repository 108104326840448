export default {
  wrapper: {

  },
  bg: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '50%',
    backgroundColor: '#64b5f6',
    zIndex: -1,
  },
};
