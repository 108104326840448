import React from 'react';
import { createUseStyles } from 'react-jss';
import { Field } from 'react-final-form';
import TextField from '@material-ui/core/TextField';
import styles from './styles';

const useStyles = createUseStyles(styles);

const FormInput = ({
  name,
  label,
  type = 'text',
  fullWidth,
  validate,
}) => {
  const classes = useStyles();

  return (
    <Field
      name={name}
      validate={validate}
    >
      {props => (
        <div className={classes.wrapper}>
          <TextField
            label={label}
            fullWidth={fullWidth}
            name={name}
            type={type}
            value={props.input.value}
            onChange={props.input.onChange}
          />
          {props.meta.error && props.meta.touched && <span className={classes.error}>{props.meta.error}</span>}
        </div>
      )}
    </Field>
  )
};

export default FormInput;
