import React from 'react';
import { createUseStyles } from 'react-jss';
import { Form } from 'react-final-form';
import {
  useHistory,
  useLocation,
} from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import FormInput from 'components/FormInput';
import AppButton from 'components/AppButton';
import { required } from 'utils/validations';
import AuthService from '../utils/services';
import styles from './styles';

const useStyles = createUseStyles(styles);

const Login = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/" } };

  const onSubmit = React.useCallback(values => {
    AuthService
      .loginUser(values)
      .then(() => {
        history.replace(from);
      });
  }, []);

  return (
    <div className={classes.wrapper}>
      <Paper elevation={3}>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <FormInput
                name="email"
                label="Email"
                validate={required}
                fullWidth
              />
              <FormInput
                name="password"
                type="password"
                label="Password"
                validate={required}
                fullWidth
              />
              <AppButton
                type="submit"
                text="Log In"
              />
            </form>
            )}
        />
      </Paper>
    </div>
  )
};

export default Login;
