import {
  SET_INIT,
  UPDATE_SENSOR,
} from '../utils/constants';
import SensorServices from '../utils/services/sensor';

const Reducer = (state, action) => {
  switch (action.type) {
    case SET_INIT:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_SENSOR:
      return {
        sensorsHumidity: SensorServices.updateItem(state.sensorsHumidity, action.payload),
        sensorsSmoke: SensorServices.updateItem(state.sensorsSmoke, action.payload, SensorServices.conditionAlarm),
        sensorsFlood: SensorServices.updateItem(state.sensorsFlood, action.payload, SensorServices.conditionAlarm),
        sensorsMotion: SensorServices.updateItem(state.sensorsMotion, action.payload, SensorServices.conditionAlarm),
        sensorsLight: SensorServices.updateItem(state.sensorsLight, action.payload, SensorServices.conditionOnOff),
        sensorsLock: SensorServices.updateItem(state.sensorsLock, action.payload, SensorServices.conditionAlarm),
      };
    default:
      return state;
  }
};

export default Reducer;
