import React from 'react';
import { createUseStyles } from 'react-jss';
import Paper from '@material-ui/core/Paper';
import SensorMotion from '../SensorMotion';
import SensorWater from '../SensorWater';
import SensorFire from '../SensorFire';
import styles from './styles';

const useStyles = createUseStyles(styles);

const SensorsCommon = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Paper className={classes.wrapperSensors} elevation={3}>
        <SensorMotion isEnabled />
        <SensorWater />
        <SensorFire />
      </Paper>
    </div>
  )
};

export default SensorsCommon;
