export default {
  wrapper: {
    '& > div': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& button': {
      position: 'absolute',
      right: 15,
    },
    '& img': {
      height: 56,
    },
  },
  title: {
    color: '#fff',
  },
};
