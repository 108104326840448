import React from 'react';
import { createUseStyles } from 'react-jss';
import SensorSingle from '../SensorSingle';
import styles from './styles';

const useStyles = createUseStyles(styles);

const SensorImageBottom = ({
  image,
  alt,
  text,
  onClick,
}) => {
  const classes = useStyles();

  return (
    <SensorSingle onClick={onClick}>
      <div className={classes.text}>
        {text}
      </div>
      <img
        src={image}
        className={classes.image}
        alt={alt}
      />
    </SensorSingle>
  )
};

export default SensorImageBottom;
