import React from 'react';
import { Context } from '../../../Store';
import ModalSensors from '../ModalSensors';
import imageOn from './images/on.svg';
import imageOff from './images/off.svg';
import imageOffline from './images/offline.svg';

const ModalSensorsSmoke = () => {
  const [ { sensorsSmoke }, ] = React.useContext(Context);

  return (
    <ModalSensors
      items={sensorsSmoke}
      stateOn="On"
      stateOff="Off"
      stateOffline="Offline"
      imageOn={imageOn}
      imageOff={imageOff}
      imageOffline={imageOffline}
    />
  );
};

export default ModalSensorsSmoke;
