import React from 'react';
import Reducer from '../Reducer'

export const initialState = {
  sensorsSmoke: [],
  sensorsFlood: [],
  sensorsMotion: [],
  sensorsHumidity: [{
    temperature: 25,
    humidity: 39,
  }],
  sensorsLight: [],
  sensorsLock: [],
};

const Store = ({ children }) => {
  const [ state, dispatch ] = React.useReducer(Reducer, initialState);

  return (
    <Context.Provider value={[state, dispatch]}>
      {children}
    </Context.Provider>
  )
};

export const Context = React.createContext(initialState);

export default Store;