import { TEXT_CAPTION } from 'utils/constants/colors';

export default {
  wrapper: {
    textAlign: 'center',
    cursor: 'pointer',
  },
  image: {
    height: 140,
    marginBottom: 20,
  },
  text: {
    color: TEXT_CAPTION,
    fontSize: 35,
  },
};
