import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Header from 'components/Header';
import Login from 'modules/Auth/Login';
import MobileMockUp from 'components/MobileMockUp';
import PrivateRoute from 'modules/Auth/PrivateRoute';
import Dashboard from 'modules/Dashboard';

const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#03a9f4',
        contrastText: '#fff',
      },
    }
  },
);

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <Router>
        <Header/>
        <MobileMockUp />
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <PrivateRoute path="/">
            <Dashboard />
          </PrivateRoute>
        </Switch>
        <NotificationContainer />
      </Router>
    </MuiThemeProvider>
  );
}

export default App;
