export default {
  wrapper: {
    display: 'none',
    position: 'fixed',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 9999,
    backgroundColor: '#64b5f6',
    color: '#fff',
    '& h4': {
      fontWeight: 'bold',
    },
  },
  '@media (max-width: 960px)': {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '@global': {
      body: {
        overflow: 'hidden',
      },
    },
  }
};
