import React from 'react';
import { createUseStyles } from 'react-jss';
import noop from 'lodash/noop';
import styles from './styles';

const useStyles = createUseStyles(styles);

const SensorImageTop = ({
  image,
  alt,
  text,
  onClick = noop,
}) => {
  const classes = useStyles();

  return (
    <div
      className={classes.wrapper}
      onClick={onClick}
    >
      <img
        src={image}
        className={classes.image}
        alt={alt}
      />
      <div className={classes.text}>
        {text}
      </div>
    </div>
  )
};

export default SensorImageTop;
