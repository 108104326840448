import React from 'react';
import imageOn from './images/ic_widget_bulb_on.png';
import imageOff from './images/ic_widget_bulb_off.png';
import { Context } from '../../../Store';
import SensorImageBottom from '../SensorImageBottom';
import SensorServices from '../../../utils/services/sensor';
import AppModal from '../../../../../components/AppModal';
import ModalSensorsLight from '../../Modal/ModalSensorsLight';

const SensorBulb = () => {
  const [ { sensorsLight }, ] = React.useContext(Context);
  const [ isOpenModal, setIsOpenModal ] = React.useState(false);
  const isLighted = React.useMemo(() => sensorsLight.some(SensorServices.isActiveCheck), [ sensorsLight ]);
  const image = React.useMemo(() => isLighted ? imageOn : imageOff, [ isLighted ]);
  const text = React.useMemo(() => isLighted ? 'Lights on' : 'Lights off', [ isLighted ]);
  const showModal = React.useCallback(() => {
    if (sensorsLight.length) setIsOpenModal(true);
  }, [ sensorsLight ]);
  const hideModal = React.useCallback(() => {
    setIsOpenModal(false);
  }, []);

  return (
    <React.Fragment>
      <SensorImageBottom
        image={image}
        onClick={showModal}
        alt="Sensor Lights"
        text={text}
      />
      <AppModal
        isOpen={isOpenModal}
        onClose={hideModal}
      >
        <ModalSensorsLight />
      </AppModal>
    </React.Fragment>
  );
};

export default SensorBulb;
