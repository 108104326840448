import React from 'react';
import { Context } from '../../../Store';
import ModalSensors from '../ModalSensors';
import { parse as parseLock } from './utils/services/sensorsLock';
import { parse as parseFlood } from './utils/services/sensorsFlood';
import { parse as parseHumidity } from './utils/services/sensorsHumidity';
import { parse as parseMotion } from './utils/services/sensorsMotion';

const ModalSensorsEnvironment = () => {
  const [ {
    sensorsLock,
    sensorsFlood,
    sensorsMotion,
    sensorsHumidity,
  }, ] = React.useContext(Context);
  const items = React.useMemo(() => {
    return [
      ...parseHumidity(sensorsHumidity),
      ...parseLock(sensorsLock),
      ...parseFlood(sensorsFlood),
      ...parseMotion(sensorsMotion),
    ];
  }, [
    sensorsLock,
    sensorsFlood,
    sensorsMotion,
    sensorsHumidity,
  ]);

  return (
    <ModalSensors items={items} />
  );
};

export default ModalSensorsEnvironment;
