import React from 'react';
import { createUseStyles } from 'react-jss';
import Paper from '@material-ui/core/Paper';
import noop from 'lodash/noop';
import styles from './styles';

const useStyles = createUseStyles(styles);

const SensorSingle = ({
  children,
  onClick = noop,
}) => {
  const classes = useStyles();

  return (
    <Paper
      onClick={onClick}
      className={classes.wrapper}
      elevation={3}
    >
      {children}
    </Paper>
  )
};

export default SensorSingle;
