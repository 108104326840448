import React from 'react';
import imageOn from './images/ic_widget_fire_on.png';
import imageOff from './images/ic_widget_fire_off.png';
import SensorImageTop from '../SensorImageTop';
import { Context } from '../../../Store';
import SensorServices from '../../../utils/services/sensor';
import AppModal from '../../../../../components/AppModal';
import ModalSensorsSmoke from '../../Modal/ModalSensorsSmoke';

const SensorFire = () => {
  const [ { sensorsSmoke }, ] = React.useContext(Context);
  const [ isOpenModal, setIsOpenModal ] = React.useState(false);
  const isSmoked = React.useMemo(() => sensorsSmoke.some(SensorServices.isActiveCheck), [ sensorsSmoke ]);
  const image = React.useMemo(() => isSmoked ? imageOn : imageOff, [ isSmoked ]);
  const showModal = React.useCallback(() => {
    if (sensorsSmoke.length) setIsOpenModal(true);
  }, [ sensorsSmoke ]);
  const hideModal = React.useCallback(() => {
    setIsOpenModal(false);
  }, []);

  return (
    <React.Fragment>
      <SensorImageTop
        image={image}
        onClick={showModal}
        alt="Sensor smoke"
        text="Smoke"
      />
      <AppModal
        isOpen={isOpenModal}
        onClose={hideModal}
      >
        <ModalSensorsSmoke />
      </AppModal>
    </React.Fragment>
  );
};

export default SensorFire;
