export default {
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  content: {
    position: 'absolute',
    top: 20,
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    maxWidth: 626,
    maxHeight: 626,
    overflowY: 'auto',
    '&:focus': {
      outline: 'none',
    },
  },
};
