import React from 'react';
import { createUseStyles } from 'react-jss';
import HumidityImage from './images/ic_widget_humidity.png';
import { Context } from '../../../Store';
import SensorSingle from '../SensorSingle';
import AppModal from '../../../../../components/AppModal';
import ModalSensorsEnvironment from '../../Modal/ModalSensorsEnvironment';
import styles from './styles';

const useStyles = createUseStyles(styles);

const SensorEnvironment= () => {
  const classes = useStyles();
  const [ { sensorsHumidity }, ] = React.useContext(Context);
  const [ isOpenModal, setIsOpenModal ] = React.useState(false);
  const { humidity, temperature } = React.useMemo(() => ({
    temperature: Math.floor(sensorsHumidity[0].temperature),
    humidity: Math.floor(sensorsHumidity[0].humidity),
  }), [ sensorsHumidity ]);
  const showModal = React.useCallback(() => {
    if (sensorsHumidity.length) setIsOpenModal(true);
  }, [ sensorsHumidity ]);
  const hideModal = React.useCallback(() => {
    setIsOpenModal(false);
  }, []);

  return (
    <React.Fragment>
      <SensorSingle onClick={showModal}>
        <div className={classes.wrapperTemperatureCaption}>
          <div>&#216;</div>
          <div>
            Temperature
          </div>
        </div>
        <div className={classes.wrapperTemperatureValue}>
          <div>
            {temperature}
          </div>
          <div>
            &#176;
          </div>
        </div>
        <div className={classes.wrapperHumidity}>
          <img
            src={HumidityImage}
            alt="humidity"
          />
          <div>
            {humidity}%
          </div>
        </div>
      </SensorSingle>
      <AppModal
        isOpen={isOpenModal}
        onClose={hideModal}
      >
        <ModalSensorsEnvironment />
      </AppModal>
    </React.Fragment>
  );
};

export default SensorEnvironment;
