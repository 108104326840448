import clientWithToken from 'request/clientWithToken';
import { STORAGE_ACCOUNT_ID } from 'modules/Auth/utils/constants';

const getAccountData = () =>
  clientWithToken.get(
    `/account/${localStorage.getItem(STORAGE_ACCOUNT_ID)}`,
  );

export {
  getAccountData,
};
