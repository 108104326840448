import {
  TYPE_FLOOD,
  TYPE_HUMIDITY,
  TYPE_LIGHT,
  TYPE_LOCK,
  TYPE_MOTION,
  TYPE_SMOKE,
} from '../constants';

const baseData = (prop, rawObj) => ({
  id: prop,
  name: rawObj[prop].name,
  temperature: rawObj[prop].condition.temperature,
  isOnline: rawObj[prop].status === 'online',
});

const updateItem = (items, item, conditionFn) => {
  if (!item) return items;
  const [ id ] = Object.keys(item);

  return items.map(i => {
    if (i.id === id) {
      return conditionFn ? {
        ...i,
        isActive: conditionFn(item[id]),
      } : {
        ...i,
        ...item[id].condition,
      };
    }

    return { ...i };
  });
};

const conditionAlarm = item => !!item.condition.alarm;

const conditionOnOff = item => item.condition.onoff === 'on';

const isActiveCheck = el => el.isActive;

const isInactiveCheck = el => !el.isActive;

const getStateForMotion = item => item.isActive ? 'On' : 'Off';

const getStateForLock = item => item.isActive ? 'Opened' : 'Closed';

const getStateForWater = item => item.isActive ? 'On' : 'Off';

const getStateForSmoke = item => item.isActive ? 'On' : 'Off';

const getStateForLight = item => item.isActive ? 'On' : 'Off';

export default {
  updateItem,
  conditionAlarm,
  conditionOnOff,
  isActiveCheck,
  isInactiveCheck,
  getStateForMotion,
  getStateForLight,
  getStateForLock,
  getStateForSmoke,
  getStateForWater,
  parseSensors: rawObj => {
    let sensorsSmoke = [];
    let sensorsFlood = [];
    let sensorsMotion = [];
    let sensorsHumidity = [];
    let sensorsLight = [];
    let sensorsLock = [];

    for (const prop in rawObj) {
      if (rawObj.hasOwnProperty(prop)) {

        if (rawObj[prop].type === TYPE_SMOKE) {
          sensorsSmoke.push({
            ...baseData(prop, rawObj),
            isActive: !!rawObj[prop].condition.alarm,
          });
        }

        if (rawObj[prop].type === TYPE_FLOOD) {
          sensorsFlood.push({
            ...baseData(prop, rawObj),
            isActive: !!rawObj[prop].condition.alarm,
          });
        }

        if (rawObj[prop].type === TYPE_MOTION) {
          sensorsMotion.push({
            ...baseData(prop, rawObj),
            isActive: !!rawObj[prop].condition.alarm,
          });
        }

        if (rawObj[prop].type === TYPE_HUMIDITY) {
          sensorsHumidity.push({
            ...baseData(prop, rawObj),
            ...rawObj[prop].condition,
            isActive: rawObj[prop].status === 'online',
          });
        }

        if (rawObj[prop].type === TYPE_LIGHT) {
          sensorsLight.push({
            ...baseData(prop, rawObj),
            isActive: rawObj[prop].condition.onoff === 'on',
          });
        }

        if (rawObj[prop].type === TYPE_LOCK) {
          sensorsLock.push({
            ...baseData(prop, rawObj),
            isActive: !!rawObj[prop].condition.alarm,
          });
        }
      }
    }

    return {
      sensorsSmoke,
      sensorsLock,
      sensorsFlood,
      sensorsHumidity,
      sensorsLight,
      sensorsMotion,
    };
  },
};
