import React from 'react';
import AppModal from 'components/AppModal';
import imageOn from './images/ic_widget_motion_on.png';
import imageOff from './images/ic_widget_motion_off.png';
import SensorImageTop from '../SensorImageTop';
import { Context } from '../../../Store';
import SensorServices from '../../../utils/services/sensor';
import ModalSensorsMotion from '../../Modal/ModalSensorsMotion';

const SensorMotion = () => {
  const [ { sensorsMotion }, ] = React.useContext(Context);
  const [ isOpenModal, setIsOpenModal ] = React.useState(false);
  const isMotioned = React.useMemo(() => sensorsMotion.some(SensorServices.isActiveCheck), [ sensorsMotion ]);
  const image = React.useMemo(() => isMotioned ? imageOn : imageOff, [ isMotioned ]);
  const showModal = React.useCallback(() => {
    if (sensorsMotion.length) setIsOpenModal(true);
  }, [ sensorsMotion ]);
  const hideModal = React.useCallback(() => {
    setIsOpenModal(false);
  }, []);

  return (
    <React.Fragment>
      <SensorImageTop
        image={image}
        onClick={showModal}
        alt="Sensor motion"
        text="Motion"
      />
      <AppModal
        isOpen={isOpenModal}
        onClose={hideModal}
      >
        <ModalSensorsMotion />
      </AppModal>
    </React.Fragment>
  );
};

export default SensorMotion;
