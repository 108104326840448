import {
  startLoading,
  stopLoading,
} from 'utils/common';
import { notificationShow } from 'utils/errors';
import { getAccountData } from '../../request/api';

export default {
  getIotThing: async () => {
    startLoading();
    let thing = null;

    try {
      const { data: { gateways: [{ thingName }] } } = await getAccountData();

      thing = thingName;
    } catch (e) {
      notificationShow(e, 'Dashboard');
    }
    stopLoading();

    return  thing;
  }
};
