const getColor = ({ isActive, isOnline }) => {
  if (!isOnline) return '#cd152b';
  return isActive ? '#ff7900' : '#fff';
};

export default {
  wrapper: {
    width: 190,
    height: 190,
    position: 'relative',
    margin: 5,
    textAlign: 'center',
    '& > div:first-child': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& img': {
      left: 'initial !important',
      width: 'initial !important',
      height: 'calc(100% - 30px)',
    },
  },
  image: {

  },
  caption: {
    color: (props) => getColor(props),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    padding: '3px 6px',
    backgroundColor: 'rgba(0,0,0, 0.4)',
  },
};
