import imageOn from '../../../ModalSensorsHumidity/images/on.png';
import imageOff from '../../../ModalSensorsHumidity/images/off.png';
import imageOffline from '../../../ModalSensorsHumidity/images/offline.png';
import { parseCommon } from './sensors';

const parse = items => parseCommon({
  items,
  imageOn,
  imageOff,
  imageOffline,
});

export {
  parse,
};
