import React from 'react';
import imageOn from './images/ic_widget_lock_closed.png';
import imageOff from './images/ic_widget_lock_open.png';
import SensorImageBottom from '../SensorImageBottom';
import { Context } from '../../../Store';
import SensorServices from '../../../utils/services/sensor';
import AppModal from '../../../../../components/AppModal';
import ModalSensorsLock from '../../Modal/ModalSensorsLock';

const SensorLock= () => {
  const [ { sensorsLock }, ] = React.useContext(Context);
  const [ isOpenModal, setIsOpenModal ] = React.useState(false);
  const isLocked = React.useMemo(() => sensorsLock.every(SensorServices.isInactiveCheck), [ sensorsLock ]);
  const image = React.useMemo(() => isLocked ? imageOn : imageOff, [ isLocked ]);
  const text = React.useMemo(() => isLocked ? 'Locked' : 'Opened', [ isLocked ]);
  const showModal = React.useCallback(() => {
    if (sensorsLock.length) setIsOpenModal(true);
  }, [ sensorsLock ]);
  const hideModal = React.useCallback(() => {
    setIsOpenModal(false);
  }, []);

  return (
    <React.Fragment>
      <SensorImageBottom
        image={image}
        onClick={showModal}
        alt="Sensor lock"
        text={text}
      />
      <AppModal
        isOpen={isOpenModal}
        onClose={hideModal}
      >
        <ModalSensorsLock />
      </AppModal>
    </React.Fragment>
  );
};

export default SensorLock;
