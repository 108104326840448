import React from 'react';
import { createUseStyles } from 'react-jss';
import SensorEnvironment from '../SensorEnvironment';
import SensorLock from '../SensorLock';
import SensorBulb from '../SensorBulb';
import styles from './styles';

const useStyles = createUseStyles(styles);

const SensorsSpecific = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <SensorEnvironment />
      <SensorLock />
      <SensorBulb />
    </div>
  )
};

export default SensorsSpecific;
