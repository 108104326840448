import React from 'react';
import { createUseStyles } from 'react-jss';
import Paper from '@material-ui/core/Paper';
import SmoothImage from 'react-smooth-image';
import styles from './styles';

const useStyles = createUseStyles(styles);

const ModalSensor = ({
  name,
  stateOn,
  stateOff,
  stateOffline,
  imageOn,
  isActive,
  imageOff,
  imageOffline,
  isOnline,
}) => {
  const classes = useStyles({ isActive, isOnline });
  const image = React.useMemo(() => {
      if (!isOnline) return imageOffline;
      return isActive ? imageOn : imageOff;
  }, [ isActive, isOnline ]);
  const state = React.useMemo(() => {
    if (!isOnline) return stateOffline;
    return isActive ? stateOn : stateOff;
  }, [ isActive, isOnline, stateOn, stateOff ]);

  return (
    <Paper
      className={classes.wrapper}
      elevation={3}
    >
      <SmoothImage
        src={image}
        alt="sensor"
        className={classes.image}
        transitionTime={0.2}
      />
      <div className={classes.caption}>
        <div className={classes.name}>
          {name}
        </div>
        <div className={classes.state}>
          {state}
        </div>
      </div>
    </Paper>
  )
};

export default ModalSensor;
