import React from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AppButton from 'components/AppButton';
import AuthServices from 'modules/Auth/utils/services';
import logo from './images/logo.png';
import styles from './styles';

const useStyles = createUseStyles(styles);

const Header = () => {
  const classes = useStyles();
  const history = useHistory();
  const handleLogout = React.useCallback(() => {
    AuthServices
    .logoutUser()
    .then(() => {
      history.push('/');
    });
  }, []);

  return (
    <AppBar
      position="static"
      className={classes.wrapper}
    >
      <Toolbar>
        <img src={logo} />
        <Typography
          variant="h6"
          className={classes.title}
        >
          DNA Dashboard
        </Typography>
        {AuthServices.isAuthenticated() && <AppButton
          color="default"
          onClick={handleLogout}
          text="Logout"
        />}
      </Toolbar>
    </AppBar>
  )
};

export default Header;
