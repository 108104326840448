const SET_INIT = 'SET_INIT';
const UPDATE_SENSOR = 'UPDATE_SENSOR';
const TYPE_MOTION = 'MotionSensor';
const TYPE_LOCK = 'WindowSensor';
const TYPE_FLOOD = 'FloodSensor';
const TYPE_SMOKE = 'SmokeSensor';
const TYPE_HUMIDITY = 'HumiditySensor';
const TYPE_LIGHT = 'LEDBulb';

export {
  SET_INIT,
  UPDATE_SENSOR,
  TYPE_MOTION,
  TYPE_LOCK,
  TYPE_FLOOD,
  TYPE_LIGHT,
  TYPE_HUMIDITY,
  TYPE_SMOKE,
};
