import React from 'react';
import { createUseStyles } from 'react-jss';
import noop from 'lodash/noop';
import Button from '@material-ui/core/Button';
import styles from './styles';

const useStyles = createUseStyles(styles);

const AppButton = ({
  type,
  text = '',
  onClick = noop,
  color = 'primary',
  variant = 'contained',
  fullWidth = false,
}) => {
  const classes = useStyles();

  return (
    <Button
      type={type}
      fullWidth={fullWidth}
      variant={variant}
      color={color}
      onClick={onClick}
    >
      {text}
    </Button>
  )
};

export default AppButton;
