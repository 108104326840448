const getState = ({ temperature, humidity }) => {
  if (!!temperature && !!humidity) return `${temperature}°, ${humidity}%`;
  return `${temperature}°`;
};

const parseCommon = ({
  items,
  imageOn,
  imageOff,
  imageOffline,
}) => items.map(i => ({
  ...i,
  imageOn,
  imageOff,
  imageOffline,
  stateOn: getState(i),
  stateOff: getState(i),
  stateOffline: 'Offline',
}));

export {
  parseCommon,
};
