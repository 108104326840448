import React from 'react';
import imageOn from './images/ic_widget_drop_on.png';
import imageOff from './images/ic_widget_drop_off.png';
import SensorImageTop from '../SensorImageTop';
import { Context } from '../../../Store';
import SensorServices from '../../../utils/services/sensor';
import AppModal from 'components/AppModal';
import ModalSensorsFlood from '../../Modal/ModalSensorsFlood';

const SensorWater = () => {
  const [ { sensorsFlood }, ] = React.useContext(Context);
  const [ isOpenModal, setIsOpenModal ] = React.useState(false);
  const isFlooded = React.useMemo(() => sensorsFlood.some(SensorServices.isActiveCheck), [ sensorsFlood ]);
  const image = React.useMemo(() => isFlooded ? imageOn : imageOff, [ isFlooded ]);
  const showModal = React.useCallback(() => {
    if (sensorsFlood.length) setIsOpenModal(true);
  }, [ sensorsFlood ]);
  const hideModal = React.useCallback(() => {
    setIsOpenModal(false);
  }, []);

  return (
    <React.Fragment>
      <SensorImageTop
        image={image}
        onClick={showModal}
        alt="Sensor flood"
        text="Flood"
      />
      <AppModal
        isOpen={isOpenModal}
        onClose={hideModal}
      >
        <ModalSensorsFlood />
      </AppModal>
    </React.Fragment>
  );
};

export default SensorWater;
