export default {
  wrapper: {
    marginBottom: 30,
  },
  wrapperSensors: {
    borderRadius: '15px !important',
    maxWidth: '85%',
    padding: '50px 0',
    marginTop: 30,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
};
