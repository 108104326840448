export default {
  wrapper: {
    backgroundColor: '#e3f2fd',
    height: 'calc(100% - 64px)',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    '& > div': {
      minWidth: 500,
      padding: '7px 10px 15px 10px',
    },
  },
};
