import React from 'react';
import { createUseStyles } from 'react-jss';
import { Context } from '../../Store';
import {
  SET_INIT,
  UPDATE_SENSOR,
} from '../../utils/constants';
import SubscriberServices from '../../utils/services/subscriber';
import SensorServices from '../../utils/services/sensor';
import styles from './styles';

const useStyles = createUseStyles(styles);

const Shadow = ({ thing }) => {
  const classes = useStyles();
  const [ , dispatch ] = React.useContext(Context);
  const handleStatus = React.useCallback(({ state: { reported } }) => {
    dispatch({ type: SET_INIT, payload: SensorServices.parseSensors(reported) });
  }, [ ]);

  React.useEffect(() => {
    SubscriberServices.subscribe(
      thing,
      handleStatus,
      handleStateChange,
    );

    return function cleanup() {
      SubscriberServices.unsubscribe(thing);
    };
  },  [ ]);

  const handleStateChange = React.useCallback(({ state: { reported, desired } }) => {
    dispatch({ type: UPDATE_SENSOR, payload: reported || desired });
  }, [ ]);

  return (
    <div className={classes.wrapper} />
  );
};

export default Shadow;
