import React from 'react';
import { Context } from '../../../Store';
import ModalSensors from '../ModalSensors';
import imageOn from './images/on.svg';
import imageOff from './images/off.svg';
import imageOffline from './images/offline.svg';
import { parseCommon } from './utils/services/sensors';

const ModalSensorsLight = () => {
  const [ { sensorsLight }, ] = React.useContext(Context);

  const items = React.useMemo(() => parseCommon(sensorsLight), [ sensorsLight ]);

  return (
    <ModalSensors
      items={items}
      stateOn="On"
      stateOff="Off"
      imageOn={imageOn}
      imageOff={imageOff}
      imageOffline={imageOffline}
    />
  );
};

export default ModalSensorsLight;
