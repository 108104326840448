import axios from 'axios';
import get from 'lodash/get';
import AuthServices from 'modules/Auth/utils/services';
import { STORAGE_TOKEN } from 'modules/Auth/utils/constants';

const instance = axios.create({
  baseURL:`${window.BACKEND_URL}/api/v1`,
  timeout: 20000,
  headers: {
    'X-Client-Application': 'dna-dashboard',
    'X-Client-Build-Version': '0.1.0',
    'X-Client-Device-Id': 'YW5kcm9pZDpiMWU2N2FhZjAwOGUyNjIy',
    'X-Client-OS-Version': 1,
    'X-Client-Platform': 'web',
  },
});

instance.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${window.localStorage.getItem(STORAGE_TOKEN)}`;

  return config;
});

instance.interceptors.response.use(response => {
  return response;
}, error => {
  const status = get(error, 'response.status',0);

  if (status === 401 || status === 403) {
    AuthServices.cleanUp();
    window.location.href = '/';
  }
  return Promise.reject(error);
});

export default instance;
