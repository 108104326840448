import { NotificationManager } from 'react-notifications';
import get from 'lodash/get';

const notificationShow = (e, title) => {
  NotificationManager.error(
    get(e, 'response.data.title', get(e, 'response.data.details', get(e, 'message'))),
    title,
  )
};

export {
  notificationShow,
};
