import React from 'react';
import { createUseStyles } from 'react-jss';
import Modal from '@material-ui/core/Modal';
import styles from './styles';

const useStyles = createUseStyles(styles);

const AppModal = ({
  isOpen,
  onClose,
  children,
}) => {
  const classes = useStyles();
  const handleClose = React.useCallback(() => {
    onClose();
  }, [ ]);

  return (
      <Modal
        open={isOpen}
        onClose={handleClose}
        style={styles.wrapper}
      >
        <div className={classes.content}>
          {children}
        </div>
      </Modal>
  )
};

export default AppModal;
