const startLoading = () => {
  document.body.className = document.body.className + ' loading';
};

const stopLoading = () => {
  document.body.className = document.body.className.replace('loading','');
};

export {
  startLoading,
  stopLoading,
};
