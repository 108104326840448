import client from 'request/client';
import clientWithToken from 'request/clientWithToken';

const login = data =>
  client.post(
    `/oauth/token`,
    'grantType=password',
    {
      auth: {
        username: data.email,
        password: data.password,
      },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    },
  );

const logout = () =>
  clientWithToken.delete(
    `/oauth`,
  );

export {
  login,
  logout,
};
