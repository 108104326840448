import { TEXT_CAPTION } from 'utils/constants/colors';

const textStyle = {
  color: TEXT_CAPTION,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
};

export default {
  wrapperTemperatureCaption: {
    ...textStyle,
    alignItems: 'center',
    '& div:first-child': {
      fontSize: 16,
    },
    '& div:last-child': {
      fontSize: 32,
    },
  },
  wrapperTemperatureValue: {
    ...textStyle,
    '& div:first-child': {
      fontSize: 100,
    },
    '& div:last-child': {
      fontSize: 90,
    },
  },
  wrapperHumidity: {
    ...textStyle,
    alignItems: 'center',
    '& div:last-child': {
      fontSize: 40,
    },
    '& img': {
      height: 30,
      marginRight: 10,
    },
  },
};
